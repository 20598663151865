<template>
    <vx-card :title="'Work Order Execution - Execution Form'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Work Order ID</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="woCode" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Work Order Description</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="description" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Purpose</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="purpose" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Bill Of Material ID</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="bomCode" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="warehouseName" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>

                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Cost Center</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <multiselect :allowEmpty="false" :options="optionCostCenter" v-model="costCenter"
                            :select-label="''" :deselect-label="''" :placeholder="'Type to search'" :disabled="true"
                            :searchable="true" :custom-label="customLabelCostCenter" />
                    </div>
                </div>

                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Request Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="requestDate" disabled>
                        </datepicker>
                    </div>
                </div>

                <div class="vx-row mb-6" style="width: 50%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Posting Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="postingDate"
                            placeholder="Select Date" :disabledDates="disabledDatesRequest" :cleared="() => {
                                postingDate = null
                            }">
                        </datepicker>
                    </div>
                </div>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <vs-divider style="width: 60%; margin-left: 30%">
                    List Attachment
                </vs-divider>
                <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile"
                                v-for="(tr) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.file_name }}</td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Show" v-if="tr.file_url != ''">
                                        <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                            @click.stop="handleShowAttachment(tr)" />
                                    </vx-tooltip>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>


        <h3><b>Parent (Sku Result)</b></h3>
        <hr />
        <br />
        <div class=" vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="width: 28%">SKU Description</th>
                            <th style="width: 5%">HU</th>
                            <th style="width: 5%">Planned Qty</th>
                            <th style="width: 5%">Actual Qty</th>
                            <th style="width: 5%">Delta</th>
                            <th style="width: 10%">Settlement</th>
                            <th style="width: 14%">Storage Area</th>
                            <!-- <th style="width: 7%;">Batch Internal</th> -->
                            <th style="width: 7%;">Batch External</th>
                            <th style="width: 8%;">Expired Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tr, index) in parentLines" :key="index">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="tr.sku_code + ' / ' + tr.item_name" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="tr.qty" :readonly="true"
                                    style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="tr.actual_qty" :readonly="!editableActualQty"
                                    style="width: auto;" @keydown="validateKeyPress" @input="(val) => { onChangeActualQty(val, index) }"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="tr.delta_qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <multiselect v-model="tr.selectedSettlement" placeholder="Select settlement"
                                    :options="settlementOption" track-by="value" label="name" :multiple="false"
                                    :searchable="false" :allow-empty="false" :select-label="''" deselect-label=""
                                    disabled />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px;">
                                <v-select :filterable="false" :clearable="false" class="w-full"
                                    :options="tr.option_warehouse_area" v-model="tr.selectedWarehouseArea" />
                            </td>
                            <!-- <td class="td vs-table--td align-top" style="padding: 5px;">
                                <vs-input v-model="tr.batchInternal" style="width: auto;"></vs-input>
                            </td> -->
                            <td class="td vs-table--td align-top" style="padding: 5px;">
                                <vs-input v-model="tr.batchExternal" style="width: auto;" disabled></vs-input>
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px;">
                                <vs-input :value="formatDate(tr.expired_date)" style="width: auto;" disabled></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="width: 10%">MAP</th>
                            <th style="width: 10%">MAP Execute</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tr, index) in parentLines" :key="index">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="formattedPrice(tr.MAP)" disabled style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="formattedPrice(tr.lastMAP)" disabled
                                    :style="{ backgroundColor: formattedPrice(tr.MAP) != formattedPrice(tr.lastMAP) ? 'yellow' : 'white' }"
                                    style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="width: 10%">Subtotal MAP</th>
                            <th style="width: 10%">Subtotal MAP Execute</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tr, index) in parentLines" :key="index">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="tr.subTotalText" disabled style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="tr.subTotalLastMAPText" disabled style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <h3><b>Child (Sku Source)</b></h3>
        <hr />
        <br />
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="vertical-align: middle; width: 35%" rowspan="2">SKU Description</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">HU</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">Qty</th>
                            <th style="text-align: center;" colspan="3">Actual</th>
                        </tr>
                        <tr>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                            <th>MAP</th>
                            <th>MAP Execute</th>
                            <th>Subtotal MAP</th>
                            <th>Subtotal MAP Execute</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in skuLines">
                            <!-- item line -->
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.sku_code + ' / ' + tr.item_name" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>

                                <!-- first actual lines -->
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.actual_lines[0].storage_area" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.actual_lines[0].batch_number" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="formatDate(tr.actual_lines[0].expired_date)" :readonly="true"
                                        style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="formattedPrice(tr.MAP)" disabled style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input
                                        :style="{ backgroundColor: formattedPrice(tr.MAP) != formattedPrice(tr.lastMAP) ? 'yellow' : 'white' }"
                                        :value="formattedPrice(tr.lastMAP)" disabled style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.subTotalMAPText" disabled style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.subTotalLastMAPText" disabled style="width: auto;"></vs-input>
                                </td>
                            </tr>
                            <!-- add actual line -->
                            <!-- <template v-for="(trx, indexActual) in skuLines[index].actual_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexActual"
                                    v-if="indexActual > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.storage_area" :readonly="true"
                                            style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input v-model="trx.batch_number" :readonly="true"
                                            style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input :value="formatDate(trx.expired_date)" :readonly="true"
                                            style="width: auto;"></vs-input>
                                    </td>
                                </tr>
                            </template> -->
                        </template>
                    </tbody>
                </table>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th>TOTAL</th>
                            <th>{{ totalMAP }}</th>
                            <th>{{ totalLastMAP }}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="primary" v-if="this.$store.getters['user/hasPermissions']('edit')" v-on:click="handleSave">Save</vs-button>
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        vSelect,
        Datepicker,
    },
    data() {
        return {
            id: null,
            purpose: null,
            readonly: true,
            status: null,
            woCode: null,
            description: null,
            bomCode: null,
            warehouseID: 0,
            warehouseName: null,
            actualQty: 0,
            optionCostCenter: [],
            costCenter: null,
            requestDate: null,
            postingDate: moment().format("YYYY-MM-DD"),
            purposeType: null,
            purposeMTM: 0,
            purposeCurah: 1,
            editableActualQty: true,
            fileAttachment: [],
            settlementOption: [
                {
                    value: "0",
                    name: "Expense",
                },
                {
                    value: "1",
                    name: "Capitalize",
                },
            ],
            parentLines: [
                {
                    sku_code: null,
                    item_name: null,
                    unit: null,
                    qty: 0,
                    actual_qty: 0,
                    delta_qty: 0,
                    selectedSettlement: null,
                    option_warehouse_area: [],
                    expired_date: null,
                    batchInternal: "",
                    batchExternal: "",
                    selectedWarehouseArea: null,
                    supplierCode: "",
                    MAP: 0,
                    lasMAP: 0,
                    subTotal: 0,
                    subTotalText: "0",
                    subTotalLastMAP: 0,
                    subTotalLastMAPText: "0"
                }
            ],
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    unit: null,
                    qty: 0,
                    MAP: 0,
                    lastMAP: 0,
                    subTotal: 0,
                    subTotalText: "0",
                    subTotalLastMAP: 0,
                    subTotalTextLastMAP: "0",
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            storage_area: null,
                            batch_number: null,
                            expired_date: null,
                        },
                    ],
                }
            ],
            totalMAP: 0,
            totalLastMAP: 0
        }
    },
    watch: {
        'parentLines': {
            deep: true,
            handler(newVal) {
                const item = newVal[0]
                item.batchExternal = `${item.batchInternal}${item.supplierCode}`;
                const currUOM = item.qty_uom
                const currMAP = item.map
                const currLastMAP = item.lastMAP.toFixed(7)
                const currQTY = item.actual_qty

                const subTotal = currUOM * currMAP * currQTY
                const subTotalLastMAP = currUOM * currLastMAP * currQTY

                const subTotalMAPText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotal)

                const subTotalLastMAPText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotalLastMAP)

                item.subTotalText = subTotalMAPText
                item.subTotalLastMAPText = subTotalLastMAPText
            }
        }
        // "tr.batchInternal": function (val) {
        //     console.log(val)
        //     this.parentLines.batchExternal = val
        // }
    },
    async mounted() {
        await this.$store.dispatch("user/getPermissions", "work-order-execution");
        this.id = this.$route.params.id;
        if (this.id) {
            this.$vs.loading();
            this.getCostCenter()
            const data = await this.getData()
            this.woCode = data.wo_code
            this.description = data.wo_description
            this.bomCode = data.bom_code
            this.warehouseID = data.warehouse_id
            this.warehouseName = data.warehouse_name
            this.skuLines = data.sku_lines.map(item => {
                const currUOM = item.qty_uom
                const currMAP = item.map
                const currLastMAP = item.last_map.toFixed(7)
                const currQTY = item.qty

                const subTotal = currUOM * currMAP * currQTY
                const subTotalLastMAP = currUOM * currLastMAP * currQTY

                const subTotalMAPText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotal)

                const subTotalLastMAPText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotalLastMAP)

                return {
                    ...item,
                    MAP: item.map,
                    lastMAP: item.last_map,
                    subTotal: subTotal,
                    subTotalLastMAP: subTotalLastMAP,
                    subTotalMAPText: subTotalMAPText,
                    subTotalLastMAPText: subTotalLastMAPText,
                }
            })

            this.requestDate = data.wo_reqest_date
            this.postingDate = data.posting_date ? data.posting_date : moment().format("YYYY-MM-DD")
            this.purpose = data.purpose_name
            this.purposeType = data.purpose_type
            this.costCenter = data.purpose_type ? this.optionCostCenter.find((costCenter) => costCenter.code === "LOGWHS") : null
            const storageOption = await this.handleGetStorageOption(data.warehouse_id)
            this.fileAttachment = data.attachments.map(attachment => ({
                file_name: attachment.name,
                file_url: attachment.url
            }))

            this.parentLines = data.parent_lines.map(item => {
                const currUOM = item.qty_uom
                const currMAP = item.map
                const currLastMAP = item.lastMAP.toFixed(7)
                const currQTY = item.qty

                const subTotal = currUOM * currMAP * currQTY
                const subTotalLastMAP = currUOM * currLastMAP * currQTY

                const subTotalMAPText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotal)

                const subTotalLastMAPText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotalLastMAP)

                const actualQTY = item.actual_qty == 0 ? item.qty : item.actual_qty
                const deltaQTY = item.qty - actualQTY

                return {
                    ...item,
                    actual_qty: actualQTY,
                    delta_qty: deltaQTY,
                    supplierCode: item.supplier_code,
                    batchExternal: `${moment().format("YYMMDD")}${item.supplier_code}`,
                    batchInternal: moment().format("YYMMDD"),
                    expired_date: moment().add(2, 'years'),
                    option_warehouse_area: storageOption,
                    selectedWarehouseArea: storageOption[0],
                    selectedSettlement: {
                        value: "0",
                        name: "Expense",
                    },
                    MAP: item.map,
                    lastMAP: item.lastMAP,
                    subTotal: subTotal,
                    subTotalLastMAP: subTotalLastMAP,
                    subTotalMAPText: subTotalMAPText,
                    subTotalLastMAPText: subTotalLastMAPText,
                }
            })

            if (this.purposeType == this.purposeMTM) {
                this.editableActualQty = false
            }

            const arrOfSubTotal = this.skuLines.map(item => item.qty * item.qty_uom * item.MAP)
            const total = arrOfSubTotal.reduce((a, b) => a + b, 0)
            const totalText = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(total)

            const arrOfSubTotalLastMAP = this.skuLines.map(item => item.qty * item.qty_uom * item.lastMAP.toFixed(7))
            const totalLastMap = arrOfSubTotalLastMAP.reduce((a, b) => a + b, 0)
            const totalLastMapText = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(totalLastMap)

            this.totalMAP = totalText
            this.totalLastMAP = totalLastMapText
            this.$vs.loading.close();
        }
    },
    methods: {
        customLabelCostCenter({ code, name }) {
            return `${code} - ${name}`;
        },
        async getCostCenter() {
            const response = await this.$http.get('/api/wms/v1/work-order-execution/get-cost-center')
            const result = response.data
            this.optionCostCenter = result
        },
        getData() {
            return new Promise((resolve, reject) => {
                this.$http.get("/api/wms/v1/work-order-execution/execute-form/" + this.id)
                    .then((resp) => {
                        if (resp.code == 200) {
                            resolve(resp.data)
                            //     this.woCode = resp.data.wo_code
                            //     this.description = resp.data.wo_description
                            //     this.bomCode = resp.data.bom_code
                            //     this.warehouseID = resp.data.warehouse_id
                            //     this.warehouseName = resp.data.warehouse_name
                            //     this.parentLines = resp.data.parent_lines
                            //     this.skuLines = resp.data.sku_lines
                            //     this.$vs.loading.close();
                        }
                        // else {
                        //     this.$vs.loading.close();
                        // }

                        // this.parentLines.forEach(async (val) => {
                        //     if (val.actual_qty != null) {
                        //         val.delta_qty = val.qty - val.actual_qty
                        //     }
                        //     val.batchExternal = moment().format("YYMMDD")
                        //     val.expired_date = moment().add(2, 'years')
                        //     val.batchInternal = moment().format("YYMMDD")
                        // })
                    })
                    .catch(error => {
                        reject(error)
                        // this.$vs.loading.close();
                    });
            })

        },
        handleGetStorageOption(warehouseID) {
            return new Promise((resolve, reject) => {
                this.$http.get("/api/wms/v1/work-order-execution/get-storage-options", {
                    params: {
                        warehouse_id: warehouseID,
                    }
                })
                    .then(resp => {
                        if (resp.code == 200) {
                            resolve(resp.data.options)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        handleShowAttachment(file) {
            let objectURL;
            // console.log(file)
            objectURL = file.file_url;

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
        handleSave() {
            if (this.purpose != "SKU ID Change" && !this.costCenter) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Cost Center",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });

                return false
            }

            if (!this.postingDate) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Posting Date",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });

                return false
            }

            var lines = [];
            this.parentLines.forEach((val) => {
                if (val.actual_qty == null) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Actual Qty required",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });

                    return false
                }

                if (!/^\d+$/.test(String(val.actual_qty))) {
                  this.$vs.notify({
                    title: "Error",
                    text: 'Quantity result not valid (must be positive integer)',
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                  });
                  return false;
                }


                lines.push({
                    settlement: val.selectedSettlement.name,
                    actual_qty: Number(val.actual_qty),
                    batch: val.batchInternal,
                    batch_external: val.batchExternal,
                    expired_date: val.expired_date,
                    storage_destination_code: val.selectedWarehouseArea
                })
            });

            const header = {
                cost_center_code: this.costCenter == null ? null : this.costCenter.code,
                posting_date: this.postingDate
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to save this data?",
                accept: () => {
                    let formData = new FormData();
                    formData.append("header", JSON.stringify(header))
                    formData.append("lines", JSON.stringify(lines));

                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/work-order-execution/save-execute/" + this.id, formData)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check-circle",
                                });
                                this.$vs.loading.close();
                                this.handleBack()
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.$vs.loading.close();
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        handleBack() {
            this.$router.push({
                name: "work-order-execution",
            });
        },
        validateKeyPress(event) {
          const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
          const key = event.key;
          if (!/^\d$/.test(key) && !allowedKeys.includes(key)) {
            event.preventDefault();
          }
        },
        onChangeActualQty(val, index) {
            // if (val > this.parentLines[index].qty) {
            //     this.$vs.notify({
            //         title: "Error",
            //         text: "Actual Qty must not be greater than Planned Qty",
            //         color: "danger",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-check"
            //     });

            //     this.parentLines[index].actual_qty = this.parentLines[index].qty
            //     return false
            // }

            if (val < 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "minimum Actual Qty is 0",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });

                this.parentLines[index].actual_qty = 0
                return false
            }

            this.parentLines[index].delta_qty = this.parentLines[index].qty - val
        },
        formattedPrice(price) {
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(price)
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
        disabledDatesRequest() {
            return {
                from: moment().toDate(),
                to: moment(this.requestDate).toDate()
            }
        },
    },
}
</script>
